<template>
  <div class="ui-flex ui-flex-col ui-w-full ui-p-4 ui-rounded-md c-bg-white">
    <button class="ui-w-full" @click="$emit('click')">
      <div class="ui-flex ui-flex-grow ui-w-full">
        <icon name="drag" color="var(--c-color-gray-400)" />
        <div class="ui-flex-grow ui-text-left c-text-btn c-text-gray-400">
          {{ title }}
        </div>
        <icon
          :name="collapsed ? 'arrow-down' : 'arrow-up'"
          class="c-icon--right"
          color="var(--c-color-gray-400)"
        />
      </div>
    </button>
    <div class="content ui-w-full" :class="[collapsed ? 'collapsed' : 'open']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Icon } from "@estrategiahq/coruja-web-ui";
export default {
  components: { Icon },
  props: {
    title: String,
    collapsed: Boolean,
  },
};
</script>

<style>
.open {
  max-height: 575px;
}

.collapsed {
  max-height: 0;
}

.content {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
</style>