<template>
  <div class="list-group-item track-item track-item-mouse ui-border ui-rounded c-border-gray-100 ui-p-4 ui-mb-2">
    <div class="c-row">
      <div class="">
        <icon name="drag" color="var(--c-color-gray-400)" />
      </div>
      <div class="">
        <c-button
        outline
          color="gray"
          class="ui-border-2 ui-rounded-full c-border-gray-400 ui-p-0 ui-h-auto c-button--dark-gray"
          @click="stopTrack(track.id)"
          v-if="track.id === trackPlayingId"
        >
          <icon name="pause" width="20" height="20"  color="var(--c-color-gray-400)"/>
        </c-button>
        <c-button
         outline
         color="gray"
          class="ui-border-2 ui-rounded-full c-border-gray-400 ui-p-0 ui-h-auto c-button--dark-gray"
          @click="playTrack(track.id)"
          v-else
        >
          <icon name="play" width="20" height="20"  class="c-icon--center"  color="var(--c-color-gray-400)" />
        </c-button>
      </div>
      <div class="ui-px-3 c-col">
        <div class="c-text-b3 c-text-gray-400 ui-text-left">
          {{ track.title }}
        </div>
          <div class="c-text-b3 c-text-gray-300 ui-text-left">
            {{ track.teachers | printList }} •  Atualizado: {{ track.created_at > track.updated_at?track.created_at:track.updated_at | dateFormat}}
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    CButton,
    Icon,
  },
  name: "TrackItem",
  props: {
    track: { type: Object, required: true },
    trackPlayingId: { type: Number, default: null },
  },
  created() {
  },
  filters: {
    printList(arr) {
      return arr.map((t) => t.name).join();
    },
  },
  methods: {

    stopTrack(id) {
      this.$emit("stop-track", id);
    },
    playTrack(id) {
      this.$emit("play-track", id);
    },
  },
};
</script>

<style l>

.track-item {
  justify-content: space-between;
}

.track-item-mouse {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}
.track-item-mouse:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.c-button--dark-gray{
    --button-bg-color: var(--c-color-gray-400);
    --button-bg-color-hover: var(--c-color-gray-200);
    --button-bg-color-active: var(--c-color-gray-500);
}
</style>
