<template>
  <div class="tooltip">
    <slot name="container"></slot>
    <div v-if="showTooltip" class="tooltiptext c-text-b3 ui-shadow-md c-text-gray-400">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    showTooltip:{
      type:Boolean,
      default:true
    }
  }
};
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 99999;
  top: 150%;
  left: 50%;
  margin-left: -130px;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}



.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;

}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
}
</style>