<template>
  <svg
    class="image"
    width="80"
    height="38"
    viewBox="0 0 80 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMin"
  >
  
    <path d="M54 15L79.9808 37.5H28.0192L54 15Z" fill="#E2DFDF" />
    <path d="M11.5 27L23.1913 37.125H-0.191343L11.5 27Z" fill="#E2DFDF" />
    <path
      d="M35.0064 10.5032C35.0064 16.3039 30.3039 21.0064 24.5032 21.0064C18.7024 21.0064 14 16.3039 14 10.5032C14 4.70243 18.7024 0 24.5032 0C30.3039 0 35.0064 4.70243 35.0064 10.5032Z"
      fill="#E2DFDF"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.image
{
  margin: 0 auto;
  display: block;
}
</style>