<template>
<div>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
  <div class="ui-p-6 c-bg-gray-100" @drop.prevent>
    <c-link
      class="
        ui-flex ui-text-left ui-p-0 ui-no-underline
        c-text-b2
        ui-mb-2
        c-text-gray-400
      "
      @click="cancel()"
    >
      <icon name="ArrowLeft" color="var(c-text-gray-400)" class="--icon-left" />
      Voltar para Lista de álbuns
    </c-link>
    <div
      class="ui-pb-lg ui-pt-lg c-text-h2 ui-text-left c-text-gray-400"
      v-if="album.id"
    >
      Editar Álbum:
    </div>
    <div
      class="ui-pb-lg ui-pt-lg c-text-h2 ui-text-left c-text-gray-400"
      v-else
    >
      Criar novo álbum
    </div>
    <div
      class="ui-pb-lg ui-pt-lg c-text-h3 ui-text-left c-text-gray-400"
      v-if="album.id"
    >
      {{ album.title }}
    </div>
    
      <div class="ui-px-4 ui-py-6 ui-mt-8 ui-mb-12 c-bg-white ui-rounded-md">
        <div class="c-row">
          <div class="c-col">
            <ValidationInput
              class="ui-mb-6"
              label="Insira o título do álbum"
              v-model="album.title"
              rules="required|title"
            />
          </div>
        </div>
        <div class="c-row ui-pt-6">
          <div class="c-col">
            <ValidationInput
              class="ui-mb-6"
              label="Insira a descrição do álbum"
              v-model="album.description"
              rules="description"
            />
          </div>
        </div>
      </div>
      <div class="c-row">
        <div class="c-col">
          <div class="c-text-h3 ui-text-left c-text-gray-400">
            Lista de faixas
          </div>
          <div class="c-text-b3 ui-text-left c-text-gray-300">
            A lista abaixo apresenta os 20 primeiros resultados, ou mais
            recentes.
          </div>
          <div class="ui-p-2 ui-mt-4">
            <c-search-bar
              placeholder="Busque pelo nome da faixa"
              v-model="search"
            />
          </div>
          <div class="ui-overflow-auto c-bg-white track-list ui-p-4">
            <draggable
              class="list-group"
              group="tracks"
              :list="tracks"
              :move="checkMove"
              @end="endMove"
            >
              <track-item
                v-for="track in tracks"
                :player.sync="player"
                :track="track"
                :track-playing-id="trackPlayingId"
                :key="track.id"
                @play-track="playTrack"
                @stop-track="stopTrack"
              />
            </draggable>
          </div>
        </div>
        <div class="c-col">
          <div class="c-text-h3 ui-text-left c-text-gray-400">Seções</div>
          <div class="c-text-b3 ui-text-left c-text-gray-300">
            É obrigatório ter ao menos uma seção, arraste as faixas ao quadro
            abaixo.
          </div>
          <div class="ui-overflow-auto section-list ui-p-4 ui-mt-4">
            <draggable
              class="list-group"
              :list="album.sections"
              group="section"
            >
              <collapsible
                v-for="(section, index) in album.sections"
                :key="`session-${index}`"
                :title="`Seção ${index}`"
                :collapsed="index !== selectedSection"
                @click="openSection(index)"
                class="ui-flex ui-w-full ui-p-4 ui-rounded c-bg-white"
              >
                <div class="ui-pt-6">
                  <div class="c-row">
                    <div class="c-col">
                      <ValidationInput
                        class="ui-mb-6"
                        label="Insira o título da seção"
                        v-model="section.title"
                        rules="required|title"
                      />
                    </div>
                  </div>
                  <div class="c-row ui-pt-6">
                    <div class="c-col">
                      <ValidationInput
                        class="ui-mb-6"
                        label="Insira a descrição da seção"
                        v-model="section.description"
                        rules="description"
                      />
                    </div>
                  </div>
                </div>
                <div class="c-row ui-pt-6">
                  <div class="c-col">
                    <draggable
                      v-model="section.tracks"
                      :key="`track-${index}`"
                      class="
                        list-group
                        section-droparea
                        ui-border-2
                        c-border-gray-200
                        ui-border-dashed ui-rounded-lg ui-overflow-auto
                      "
                      group="tracks"
                    >
                      <track-item
                        v-for="track in section.tracks"
                        :track="track"
                        :track-playing-id="trackPlayingId"
                        :key="track.id"
                        @play-track="playTrack"
                        @stop-track="stopTrack"
                      />
                      <div
                        v-if="
                          !(
                            section &&
                            section.tracks &&
                            section.tracks.length > 0
                          )
                        "
                        class="
                          c-text-gray c-text-input
                          ui-w-full ui-h-full ui-flex
                        "
                      >
                        <span class="ui-m-auto">
                          Arraste e solte as faixas aqui
                        </span>
                      </div>
                    </draggable>
                  </div>
                </div>
                <div class="c-row ui-pt-6">
                  <div class="c-col">
                    <c-button
                      class="ui-uppercase ui-w-full button-red c-text-btn-sm"
                      @click="deleteSectionMsg(index)"
                      >remover seção</c-button
                    >
                  </div>
                </div>
              </collapsible>
            </draggable>
          </div>
        </div>
      </div>
      <div class="c-d-flex ui-items-center ui-justify-end ui-mt-4">
        <c-button outline @click="newSection">
          Adicionar nova seção <icon name="add" class="c-icon--right" />
        </c-button>
      </div>
      <div class="c-row">
        <div class="c-col">
          <div
            class="
              c-text-h3
              ui-text-left
              c-text-gray-400
              ui-flex ui-items-center
            "
          >
            Thumbnail do álbum - 352 x 352px
            <tooltip>
              <template v-slot:container>
                <icon
                  filled
                  width="16"
                  height="16"
                  color="var(--c-color-gray-400)"
                  name="info"
                />
              </template>
              <span>
                A imagem deve ser quadrada para ser utilizada em buscas, listas
                e no player do Cast</span
              >
              <div
                class="
                  ui-border ui-p-2 ui-h-20 ui-w-20
                  c-border-gray-200
                  ui-mx-auto ui-my-2
                "
              >
                <div class="c-bg-gray-200 ui-w-full ui-h-full">
                  <div class="ui-h-full ui-flex ui-items-center">
                    <image-symbol class="ui-h-6 ui-w-10 ui-m-auto" />
                  </div>
                </div>
              </div>
            </tooltip>
          </div>
          <div class="c-text-b3 ui-text-left c-text-gray-300">
            O sistema insere a thumbnail padrão da vertical se nenhuma imagem
            for selecionada
          </div>
          <div class="ui-mt-4">
            <image-uploader
              id="image"
              :imageUrl="album.imageUrl"
              @file-uploaded="onImageUploaded"
              @image-upload-error="onUploadImageError"
            />
          </div>
        </div>
        <div class="c-col">
          <div
            class="
              c-text-h3
              ui-text-left
              c-text-gray-400
              ui-flex ui-items-center
            "
          >
            Capa do álbum - 1248 x 464px
            <tooltip>
              <template v-slot:container>
                <icon
                  filled
                  width="16"
                  height="16"
                  color="var(--c-color-gray-400)"
                  name="info"
                />
              </template>
              <span>
                A imagem enviada deve ser retangular para ser utilizada nos
                cards da página inicial e no álbum aberto do Cast
              </span>
              <div
                class="
                  ui-border ui-p-2 ui-h-20 ui-w-40
                  c-border-gray-200
                  ui-mx-auto ui-my-2
                "
              >
                <div class="c-bg-gray-200 ui-w-full ui-h-full">
                  <div class="ui-h-full ui-flex ui-items-center">
                    <image-symbol class="ui-h-6 ui-w-10 ui-m-auto" />
                  </div>
                </div>
              </div>
            </tooltip>
          </div>
          <div class="c-text-b3 ui-text-left c-text-gray-300">
            O sistema insere a capa padrão da vertical se nenhuma imagem for
            selecionada
          </div>
          <div class="ui-mt-4">
            <image-uploader
              wide
              id="image-wide"
              :imageUrl="album.imageWideUrl"
              @file-uploaded="onImageWideUploaded"
              @image-upload-error="onUploadImageWideError"
            />
          </div>
        </div>
      </div>
      <div
        class="c-text-h3 ui-text-left ui-mt-12 c-text-gray-400 ui-items-center"
      >
        Publicar álbum após salvar
      </div>
      <div class="c-bg-white ui-p-4 ui-flex ui-shadow">
        <div class="c-text-b2 c-text-gray-400 ui-justify-self-start">
          Álbuns despublicados não aparecem aos alunos, para salvar sem publicar
          desative o botão ao lado
        </div>
        <div class="ui-ml-auto">
          <c-toggle v-model="is_published"></c-toggle>
        </div>
      </div>

      
    <modal :show="deleteDialog" @close="deleteDialog = false">
      <template v-slot:title>Deseja remover esta seção?</template>
      <template v-slot:actions>
        <div class="ui-flex">
          <div class="ui-flex-1">
            <c-link
              class="ui-w-full ui-h-full c-text-gray-300"
              component="button"
              @click="deleteDialog = false"
              >Não, cancelar</c-link
            >
          </div>
          <div class="ui-flex-1">
            <c-button
              class="ui-w-full"
              color="primary"
              @click="deleteSection(selectedSection)"
              >Sim, remover</c-button
            >
          </div>
        </div>
      </template>
    </modal>
  </div>
  <actions-footer :cancelRoute="'/'" @action-click="handleActionClick">
        <template v-slot:actions>
          <c-button class="ui-mr-4" outline @click="save(handleSubmit)"
            >Somente salvar</c-button
          >
          <c-button @click="saveAndNext(handleSubmit)"
            >Salvar e ir para estantes
            <icon name="next" outline class="c-icon--right"
          /></c-button>
        </template>
      </actions-footer>
    </ValidationObserver>
</div>
</template>

<script>
import draggable from "vuedraggable";
import {
  CSearchBar,
  CButton,
  Icon,
  CToggle,
  CLink,
} from "@estrategiahq/coruja-web-ui";

import TrackItem from "../../components/TrackItem.vue";
import Tooltip from "../../components/ToolTip.vue";
import Modal from "../../components/Modal.vue";
import ImageSymbol from "../../components/ImageSymbol.vue";
import ImageUploader from "../../components/ImageUploader.vue";
import ValidationInput from "../../components/ValidationInput.vue";
import ActionsFooter from "../../components/ActionsFooter.vue";
import { required } from "vee-validate/dist/rules";

import { extend, ValidationObserver } from "vee-validate";
import Collapsible from "../../components/Collapsible.vue";

const DEFAULT_SECTION = { title: "", description: "", tracks: [] };

extend("title", (value) => {
  if (value.length >= 3 && value.length <= 250) {
    return true;
  }

  return "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres";
});
extend("required", {
  ...required,
  message:
    "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres",
});

extend("description", (value) => {
  if (value.length <= 250) {
    return true;
  }

  return "A descrição deve conter no máximo 250 caracteres";
});

export default {
  components: {
    draggable,
    CSearchBar,
    TrackItem,
    CButton,
    Icon,
    Tooltip,
    CToggle,
    CLink,
    ImageSymbol,
    ValidationObserver,
    Collapsible,
    ValidationInput,
    Modal,
    ImageUploader,
    ActionsFooter,
  },
  data: () => ({
    splitterModel: 33,
    album: {
      id: null,
      title: "",
      description: "",
      is_published: false,
      imageKey: "",
      imageWideKey: "",
      imageUrl: "",
      imageWideUrl: "",
      sections: [{ ...DEFAULT_SECTION }],
    },
    bucketInfo: null,
    search: "",
    tracks: [],
    player: null,
    trackPlayingId: null,
    trackError: false,
    selectedSection: 0,
    timeout: null,
    deleteDialog: false,
    is_published: true,
  }),
  computed: {
    isButtonDisabled() {
      const titleLength = this.album.title.length;

      const titleValidation = titleLength > 3 && titleLength <= 250;
      const imageValidation = !!this.album.imageUrl;

      return !(titleValidation && imageValidation);
    },
  },
  created() {
    this.album.id = this.$route.params.id || null;
  },
  mounted() {
    if (this.album.id) {
      this.fetchAlbum(this.album.id);
    }
    this.mountPlayer();
    this.fetchTracks({
      pagination: this.pagination,
      filter: this.filter,
    });
  },
  destroyed() {
    this.stopTrack();
  },
  watch: {
    async search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => await this.fetchTracks({}), 300);
    },
  },
  methods: {
    onFileUploadError(msg) {
      this.$emit("toast", {
        text: msg,
        position: "top",
        type: "error",
        timeout: 3000,
      });
    },
    onUploadImageError(msg) {
      this.onFileUploadError(msg);
    },
    onUploadImageWideError(msg) {
      this.onFileUploadError(msg);
    },
    checkMove(evt) {
      for (const elm of evt.relatedContext.list) {
        if (elm.id === evt.draggedContext.element.id) {
          this.trackError = true;
          return false;
        }
      }
      return true;
    },
    endMove() {
      this.trackError = false;
    },
    listenTrackPlay(trackId) {
      this.trackPlayingId = Number(trackId);
    },
    cancel() {
      this.$router.push({ name: "albums" });
    },
    newSection() {
      if (!this.album.sections) this.album.sections = [];
      this.album.sections.push({ ...DEFAULT_SECTION });
      this.selectedSection = this.album.sections.length - 1;
    },
    toggleSeparator() {
      if (
        this.splitterModel === undefined ||
        this.splitterModel === null ||
        isNaN(this.splitterModel)
      ) {
        this.splitterModel = 0;
      }
      if (this.splitterModel === 0) {
        this.splitterModel = 33;
      } else {
        this.splitterModel = 0;
      }
    },
    onImageUploaded(data) {
      this.bucketInfo = data.bucketInfo;
      if (this.bucketInfo.key) {
        this.album.imageKey = `${this.bucketInfo.key}`;
        this.album.imageUrl = data.tempUrl;
      }
    },
    onImageWideUploaded(data) {
      this.bucketInfo = data.bucketInfo;
      if (this.bucketInfo.key) {
        this.album.imageWideKey = `${this.bucketInfo.key}`;
        this.album.imageWideUrl = data.tempUrl;
      }
    },
    async fetchTracks() {
      let search = `?page=1&per_page=20`;
      search += !this.search.length
        ? ""
        : `&search=(title[icontains]:'${this.search}')`;

      let result = await this.$s.track.fetchTracks(search);
      this.tracks = result.data;
    },
    async createOrEditAlbum() {
      if (this.album.id) {
        await this.updateAlbum(this.album.id);
      } else {
        await this.createAlbum();
      }
    },
    async save(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrEditAlbum();
          this.$emit("toast", {
            text: "Álbum salvo com sucesso",
            position: "top",
            timeout: 3000,
          });
          this.$router.push({ name: "albums" });
        } catch (error) {
          //Deve ser tratado após revisão de backend
          console.log(error.response);
          this.$emit("toast", {
            text: "Falha ao salvar álbum",
            position: "top",
            type: "error",
            timeout: 3000,
          });
        }
      });
      window.scrollTo(0, 0);
    },
    async saveAndNext(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrEditAlbum();
          this.$emit("toast", {
            text: "Álbum salvo com sucesso",
            position: "top",
            timeout: 3000,
          });
          this.$router.push({ name: "shelves" });
        } catch (error) {
          //Deve ser tratado após revisão de backend
          console.log(error.response);
          this.$emit("toast", {
            text: "Falha ao salvar álbum",
            position: "top",
            type: "error",
            timeout: 3000,
          });
        }
      });
      window.scrollTo(0, 0);
    },

    async fetchAlbum() {
      let result = await this.$s.album.fetchAlbum(this.album.id);
      const album = result.data;
      this.album.title = album.title;
      this.album.description = album.description;
      this.album.imageUrl = album.image;
      this.album.imageWideUrl = album.image_wide;
      this.album.imageKey = this.getImageKey(album.image);
      this.album.imageWideKey = this.getImageKey(album.image_wide);
      this.album.sections =
        album.sections?.map((s) => {
          s.tracks = s.tracks || [];
          return s;
        }) || [];
      this.is_published = album.is_published;
    },

    async createAlbum() {
      const sections = this.album.sections.map((s) => ({
        title: s.title,
        description: s.description,
        tracks: s.tracks,
        tracks_ids: s.tracks.map((t) => t.id),
      }));
      const data = {
        title: this.album.title,
        description: this.album.description,
        image: this.album.imageKey ? `${this.album.imageKey}.jpeg` : null,
        image_wide: this.album.imageWideKey
          ? `${this.album.imageWideKey}.jpeg`
          : null,
        sections: sections,
      };
      let result = await this.$s.album.createAlbum(data);
      let newAlbum = result.data;
      if (this.is_published && newAlbum && newAlbum.id) {
        await this.$s.album.publishAlbum(newAlbum.id);
      }
    },
    getImageKey(s) {
      return s?.split("/")[5]
        ? "images/" + s?.split("/")[5]?.replace("-raw", "")
        : "";
    },
    async updateAlbum(id) {
      const sections = this.album.sections.map((s) => ({
        title: s.title,
        description: s.description,
        tracks: s.tracks,
        tracks_ids: s.tracks.map((t) => t.id),
      }));
      const data = {
        title: this.album.title,
        description: this.album.description,
        image: this.album.imageKey ? `${this.album.imageKey}.jpeg` : null,
        image_wide: this.album.imageWideKey
          ? `${this.album.imageWideKey}.jpeg`
          : null,
        sections: sections,
      };
      let res = await this.$s.album.updateAlbum(id, data);
      if (res.data && res.data.id) {
        if (this.album.is_published && !this.is_published) {
          await this.$s.album.unpublishAlbum(this.album.id);
        } else if (!this.album.is_published && this.is_published) {
          await this.$s.album.publishAlbum(this.album.id);
        }
      }
    },

    mountPlayer() {
      const audio = new Audio();
      audio.autoplay = true;
      this.player = audio;
    },
    async playTrack(id) {
      this.trackPlayingId = id;
      let result = await this.$s.track.getTrackUrl(id);
      const trackUrl = result.data.url;
      this.player.src = trackUrl;
    },
    stopTrack() {
      this.trackPlayingId = null;
      this.player.pause();
      this.player.src = null;
    },
    deleteSectionMsg(index) {
      this.deleteDialog = true;
      this.selectedSection = index;
    },
    deleteSection(index) {
      this.album.sections.splice(index, 1);
      this.deleteDialog = false;
      if (index > 0) this.selectedSection = index - 1;
    },
    openSection(index) {
      this.selectedSection = index;
    },
    handleActionClick(type) {
      const actions = {
        CANCEL: () =>
          window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push({ name: "albums" }),
      };
      return actions[type] && actions[type]();
    },
  },
};
</script>

<style scoped>
.section-droparea {
  height: 275px;
}

.error {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.track-list {
  height: 520px;
}
.section-list {
  min-height: 520px;
  max-height: 714px;
}
.button-red {
  --button-text-color: var(--c-color-red-400);
  --button-bg-color: rgba(255, 128, 128, 0.2);
  --button-bg-color-hover: rgba(255, 128, 128, 0.3);
  --button-bg-color-active: var(--c-color-red-100);
}
</style>
